#transactionList th{
    text-align: center;
}
#transactionList td:nth-child(2),
#transactionList td:nth-child(3),
#transactionList td:nth-child(8),
#transactionList td:nth-child(9){
    text-align: center;
    width: 100px;
}
#transactionList td:nth-child(6){
    text-align: right;
}
#transactionList td:nth-child(4),
#transactionList td:nth-child(5){
    width: 150px;
}