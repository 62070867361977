#staffListTable th {
    text-align: center;
}

#staffListTable td:nth-child(1),
#staffListTable td:nth-child(2),
#staffListTable td:nth-child(4),
#staffListTable td:nth-child(9) {
    text-align: center;
}

.StaffDetailsTable {
    width: 100%;
}

.StaffDetailsTable th,
.StaffDetailsTable td {
    padding: 10px;
}