#adsTableId th {
    text-align: center;
}

#adsTableId td:nth-child(2) {
    width: 150px;
}

#adsTableId td:nth-child(3) {
    width: 200px;
}

#adsTableId td:nth-child(4),
#adsTableId td:nth-child(5),
#adsTableId td:nth-child(6),
#adsTableId td:nth-child(7),
#adsTableId td:nth-child(8) {
    width: 100px;
}

#adsTableId td:nth-child(3),
#adsTableId td:nth-child(4),
#adsTableId td:nth-child(5),
#adsTableId td:nth-child(8),
#adsTableId td:nth-child(9) {
    text-align: center;
}

#adsHistoryTableId th {
    text-align: center;
}

#adsHistoryTableId td:nth-child(1),
#adsHistoryTableId td:nth-child(2),
#adsHistoryTableId td:nth-child(3) {
    text-align: center;
    width: 100px;
}

#adsHistoryTableId td:nth-child(6) {
    text-align: left !important;
}


.table-link-hover:hover {
    color: #5b73e8 !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    text-underline-offset: 2px !important;
}

#countTableId th {
    text-align: center;
}

#countTableId td:nth-child(1),
#countTableId td:nth-child(3),
#countTableId td:nth-child(4),
#countTableId td:nth-child(5) {
    text-align: center;
}