#OrderSummeryTable {
    width: 356px;
}

#OrderSummeryTable th,
#OrderSummeryTable td {
    padding: 10px
}

#OrderSummeryTable td:nth-child(3) {
    width: 150px;
    font-weight: bold;
    font-size: 14px;
}

#OrderSummeryTable th {
    text-align: left;
    width: 127px;
    font-size: 14px;
}

#OrderItemsTableID th {
    text-align: center;
}

#OrderItemsTableID td:nth-child(1),
#OrderItemsTableID td:nth-child(2),
#OrderItemsTableID td:nth-child(4) {
    text-align: center;
}

#OrderItemsTableID td:nth-child(8),
#OrderItemsTableID td:nth-child(9),
#OrderItemsTableID td:nth-child(10),
#OrderItemsTableID td:nth-child(12) {
    text-align: right;
}

#offlineorderListTable1 th,
#offlineorderListTable1 td:nth-child(1),
#offlineorderListTable1 td:nth-child(2),
#offlineorderListTable1 td:nth-child(3),
#offlineorderListTable1 td:nth-child(4),
#offlineorderListTable1 td:nth-child(6),
#offlineorderListTable1 td:nth-child(9),
#offlineorderListTable1 td:nth-child(10) {
    text-align: center;
}

#offlineorderListTable1 td:nth-child(8) {
    text-align: left !important;
}
#offlineorderListTable1 td:nth-child(2),
#offlineorderListTable1 td:nth-child(3),
#offlineorderListTable1 td:nth-child(10){
    min-width: 100px;
}