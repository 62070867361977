#transactionLogTb th{
    text-align: center;
}
#transactionLogTb td:nth-child(2),
#transactionLogTb td:nth-child(3){
    width: 100px;
    text-align: center;
}
#transactionLogTb td:last-child{
    text-align: left;
}