#adsPosterTableId th {
    text-align: center;
}
#adsPosterTableId td:nth-child(1),
#adsPosterTableId td:nth-child(4),
#adsPosterTableId td:nth-child(5),
#adsPosterTableId td:nth-child(6),
#adsPosterTableId td:nth-child(7){
    width: 100px;
}
#adsPosterTableId td:nth-child(2)
{
    width: 350px;
}
#adsPosterTableId td:nth-child(3),
#adsPosterTableId td:nth-child(4),
#adsPosterTableId td:nth-child(5),
#adsPosterTableId td:nth-child(6),
#adsPosterTableId td:nth-child(7){
    text-align: center;
}