#rechargeTableId th,
#toprechargeTableId th {
    text-align: center;
}

#rechargeTableId td:nth-child(1),
#toprechargeTableId td:nth-child(3),
#rechargeTableId td:nth-child(2) {
    text-align: center;
}

#rechargeTableId td:nth-child(6) {
    text-align: left;
}

#rechargeTableId td:nth-child(4),
#toprechargeTableId td:nth-child(4) {
    text-align: right;
}

#rechargeTableId td:nth-child(7) {
    text-align: left !important;
}

.pagination-div {
    display: flex;
    justify-content: space-between;
    /* To create space between the Pagination and page-details */
    align-items: center;
    /* Align items vertically in the center */
}

.Mui-selected {
    /* background: #dee3fb; */
    color: #fff !important;
    background-color: #5b73e8 !important;
    border-color: #5b73e8 !important;
}

.MuiButtonBase-root {
    min-width: 30px !important;
    height: 30px !important;
    border-radius: 0px !important;
}