#customerTableId th {
    text-align: center;
}

#customerTableId td:nth-child(1),
#customerTableId td:nth-child(2),
#customerTableId td:nth-child(4) {
    text-align: center;
}

#customerTableId td:nth-child(7) {
    text-align: right;
}

#orderListTable1 th,
#orderListTable1 td:nth-child(1),
#orderListTable1 td:nth-child(2),
#orderListTable1 td:nth-child(3),
#orderListTable1 td:nth-child(6),
#orderListTable1 td:nth-child(7) {
    text-align: center;
}

#orderListTable1 td:nth-child(5) {
    text-align: right;
}

#walletLogTable th,
#pointLogTable th {
    text-align: center;
}

#walletLogTable td:nth-child(1),
#walletLogTable td:nth-child(2),
#pointLogTable td:nth-child(1),
#pointLogTable td:nth-child(2),
#shippingAddressTable td:nth-child(1),
#shippingAddressTable td:nth-child(3),
#shippingAddressTable td:nth-child(5) {
    text-align: center;
}

#walletLogTable td:nth-child(2),
#pointLogTable td:nth-child(2) {
    width: 100px;
}

#walletLogTable td:nth-child(3),
#walletLogTable td:nth-child(4),
#walletLogTable td:nth-child(5),
#pointLogTable td:nth-child(3),
#pointLogTable td:nth-child(4) {
    text-align: right;
}

#walletLogTable td:nth-child(6),
#pointLogTable td:nth-child(6) {
    text-align: left;
}

.div-link {
    cursor: pointer;
}

.spinner1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.addressTable {
    width: 100%;
}

.addressTable td,
.addressTable th {
    padding: 10px;
}

#shippingAddressTable th {
    text-align: center;
}

#executiveTableId th {
    text-align: center;
}

#executiveTableId td:nth-child(1),
#executiveTableId td:nth-child(3),
#executiveTableId td:nth-child(4),
#executiveTableId td:nth-child(5) {
    text-align: center;
}