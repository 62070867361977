#categoryTableId th {
    text-align: center;
}

#categoryTableId td:nth-child(1) {
    width: 100px;
}

#categoryTableId td:nth-child(3) {
    text-align: center;
}